.bank-card {
    @extend .cpointer;
    margin-bottom: 1.1rem;
    position: relative;
    background: var(--gray-bg);
    border-radius: 20px;
    padding: 1rem;
    border: 1px solid var(--gray-bg);

    min-height: 235px;
    @include for-desktop-big {
        min-height: 265px;
    }

    &:hover {
        background: var(--widget-active) !important;
        border: 1px solid var(--gray-bg);

        *,
        *:before {
            color: var(--white) !important;
        }
    }
}


.bank-card-img {
    position: relative;
    z-index: 1;
    width: 100%;
    // padding: 1rem;
    // min-height: 275px;

    &:before {
        content: " ";
        background-repeat: no-repeat;
        position: absolute;
        top: 45%;
        left: 61%;
        // transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        opacity: .1;
        z-index: -1;
    }

    &.bank-img-new-bg:before {
        // content: " ";
        background: url(../../../Assets/img/bank.png);
        background-repeat: no-repeat;
    }

    &.card-img-new-bg:before {
        // content: " ";
        background: url(../../../Assets/img/card.png);
        background-repeat: no-repeat;
    }
}



.add-bank-card {
    @extend .d-flex, .align-items-center, .justify-content-center, .text-center;
    border: 1px solid var(--border-gray);

    .add-bank-text {
        @extend .size-xxx;

        .add-bank-icon {
            @extend .size-lxx, .mb-3, .d-block;
        }
    }
}



.gray-bank-card {
    border: 1px solid #9c9c9c !important;
    background: #d2d2d2 !important;
}

.not-support-bank {
    border: 1px solid #ff522e !important;
}


.bcard-lable {
    @extend .size-xvi, .s-reg, .gray-text;
    display: block;
}

.bcard-p {
    @extend .size-xviii, .s-sbold, .blue-text, .ellipsisc, .ellps-l1;
    // .mb-2,
    margin-bottom: 0.4rem !important;
}

.card_bottom_bar {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translate(-50%);
    max-width: 180px;
    width: 100%;

    p {
        @extend .text-center, .mb-0, .m-auto;
        font-size: 13px !important;
        background: var(--redc);
        padding: 1px 6px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
    }

    .acinbg {
        color: var(--black) !important;
        // background: #e6e6e6 !important;
        background: var(--gray-inactive) !important;
    }
    .actbg {
        color: var(--black) !important;
        background: var(--greenc) !important;
        // background: var(--dark-greenc) !important;
    }
}

.not-support-bank {
    .card_bottom_bar p {
        color: var(--white);
    }
}

.bank-name-h {
    @extend .size-xxiii, .d-flex, .align-items-center, .justify-content-between, .gap-10;
    min-height: 30px;

    .cicon {
        @extend .size-xxx;
    }
}


// .bank-img-new-bg {
//     background: url(../../../Assets/img/bank.png);
//     background-repeat: no-repeat;
//     background-position: center;
//     background-position-x: 67%;
//     background-position-y: 55%;
//     width: 100px !important;
//     height: 100px !important;
//     opacity: 0.1;
// }
// .card-img-new-bg {
//     background: url(../../../Assets/img/card.png);
//     background-repeat: no-repeat;
//     background-position: center;
//     background-position-x: 67%;
//     background-position-y: 55%;
//     width: 100px !important;
//     height: 100px !important;
//     opacity: 0.1;
// }



// Add Debit Card
.add-debit-card {
    @extend .size-xviii, .d-flex, .align-items-center, .cpointer;
    gap: 8px;

    .add-plus {
        @extend .size-xxxv;
    }
}

.add-debit-card-field-btn {
    @extend .d-flex, .align-items-center, .gap-10;
}

// End Add Debit Card

.bcard-digit-list-scroll {
    // @extend .mb-2;
    height: 145px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.5rem 0.7rem 0 0;
    // margin-top: -0.5rem;
    // transition: all .3s ease-in-out;

    .bcard-digit-list {
        @extend .d-flex, .align-items-center, .gap-15, .cpointer, .p-2, .mb-2, .position-relative;
        background: var(--grayc);

        .bcardicon {
            @extend .size-xxv;
        }

        .bcardname {
            @extend .size-xviii, .s-sbold, .blue-text;
        }
    }
}


.bank-card-listing {
    position: relative;

    &:hover .editbc_bankin:before {
        color: var(--white) !important;
    }
}

.edit_bank_icon {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    z-index: 10;
    @extend .cpointer;

    &:hover .editbc_bankin:before {
        color: var(--bluec) !important;
    }
}

.bank-not-support {
    @extend .d-flex, .align-items-center, .justify-content-end, .mt-3;
    gap: 7px;

    *,
    .bnsp-icon:before {
        @extend .red-text;
    }

    .bnsp-icon {
        @extend .size-xx;
    }
}

.account-details-header {
    position: relative;
    background: #f4f5f8;
    padding: 18px 5px 10px 8px;
    border-radius: 15px;

    .bank-new-logo {
        @extend .d-block, .m-lg-auto;
        max-height: 50px;
    }

    .bank-new-initial {
        @extend .d-flex, .align-items-center, .justify-content-center, .m-lg-auto;
        color: #fff;
        background: #77d7b1;
        border-radius: 100px;
        font-size: 25px;
        width: 50px;
        height: 50px;
        font-family: s-sbold !important;
        line-height: 0;
    }

    .lable-input-account {
        @extend .s-reg, .mb-0;
        color: var(--gray-text);
        font-size: 0.75rem !important;

        @include for-desktop-min {
            font-size: 0.9rem !important;
        }

        @include for-desktop-big {
            font-size: 1rem !important;
        }
    }

    .form-control-account {
        @extend .s-sbold, .mb-0;
        color: var(--bluec);
        font-size: 0.75rem !important;

        @include for-desktop-min {
            font-size: 0.9rem !important;
        }

        @include for-desktop-big {
            font-size: 1rem !important;
        }
    }

    .edit-icon-account {
        @extend .size-xxv;
    }

    .account-statu-col {
        max-width: 80px;
    }
}

.bank-iframe-popup {
    .closes-bankp-icon {
        @extend .position-absolute, .cpointer, .d-flex, .align-items-center, .justify-content-center, .size-xxxv, ;
        right: 13px;
        top: 8px;
        height: 35px;
        width: 35px;
        // background: #000 !important;
    }

    // .modal-dialog {
    //     max-width: 700px;
    //     display: flex;
    //     align-items: center;
    //     min-height: calc(100% - 3.5rem);
    // }
    // .closes-banking {
    //     position: absolute;
    //     right: 4%;
    //     top: 4%;
    // }
    // .closes-banking span {
    //     background: #0c75e6 !important;
    //     height: 30px;
    //     width: 30px;
    //     display: flex !important;
    //     align-items: center;
    //     justify-content: center;
    //     margin-top: -3px;
    // }
    // .iframewidth-banking {
    //     width: 700px;
    //     background-color: #fff;
    // }
}