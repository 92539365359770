.import-excel-popup {

    .modal-dialog {
        @include for-tablet {
            max-width: 90%;
        }
        .modal-content {
            position: relative;
        }
        .modal-body {
            @extend .pt-1, .pb-2;
        }
    }    

    .import-excel-border {
        // @extend .mt-2, .mb-3;
        margin-top: 0.5rem ;
        margin-bottom: 1rem ;
        border: 1px solid var(--grayc-light-icons);
        border-radius: 11px;
        padding: 0.6rem;
    }

    .import-excel-table {
        // @extend .mt-2, .mb-3;
        // @extend .my-2;
        overflow-y: auto;
        height: 63vh;
        background: var(--grayc);

        // thead {
        //     border-top: 1px solid var(--border-gray) !important;
        // }

        &:focus-visible {
            outline: unset !important;
        }
        @include for-desktop-big-125 {
            height: 67vh;
        }
        // @include for-desktop-15 {
        @include for-desktop-big {
            height: 65vh;
        }
        @include for-desktop-big-xx {
            height: 72vh;
            // height: 1000px;
        }

        table {
            @extend .mb-0;
            position: relative;
            background: var(--white) !important;

            tr:hover {
                background: none !important;
            }

            // thead {
            //     // background: var(--grayc) !important;
            //     // border: 1px solid var(--border-gray) !important;
            //     // position: sticky;
            //     // top: 0px;
            //     // z-index: 100;
            // }

            th,
            td {
                border: 0px !important;
                // border: 1px solid var(--border-gray);  
                outline: 1px solid var(--border-gray) !important;
                &:last-child {
                    border-right: 1px solid var(--border-gray) !important;  
                }
            }

            th {
                @extend .size-xvi, .s-reg, .blue-text;
                padding: 7px !important;
                font-weight: unset;
                white-space: nowrap;
                background-color: var(--grayc) !important;

                position: sticky;
                top: 1px;
                z-index: 100;

                &:first-child {
                    // position: sticky;
                    left: 1px;
                    z-index: 300;
                    min-width: 52px;
                    background-color: var(--white);
                }

                &:nth-child(2) {
                    // position: sticky;
                    left: 52px;
                    z-index: 300;
                    min-width: 140px;
                    @include for-desktop-15 {
                        min-width: 170px;
                    }
                }
            }

            td {
                @extend .size-xvi, .s-reg;
                position: relative;
                vertical-align: top !important;
                padding: 0.5px !important;
                color: var(--gray-text) !important;
                background-color: var(--white);

                &:first-child {
                    position: sticky;
                    left: 1px;
                    z-index: 200;
                    min-width: 52px;
                    background-color: var(--white);
                    // padding: 0px !important;
                }

                &:nth-child(2) {
                    position: sticky;
                    left: 52px;
                    z-index: 200;

                    min-width: 140px;
                    @include for-desktop-15 {
                        min-width: 170px;
                    }
                }
            }



            
            &.snap-excel-table {
                td, th {   
                    padding: 5px 0.3rem !important;
                    vertical-align: middle !important;

                    &.p-0 {
                        padding: 1px !important;
                    }

                    &:first-child {
                        left: 1px !important;
                        min-width: 100px;
                        max-width: 100px;
                        width: 100px;
                        @include for-desktop-15 {
                            min-width: 110px;
                            max-width: 110px;
                            width: 110px;
                        }
                    }    
                    &:nth-child(2) {
                        left: 101px;
                        min-width: 100px;
                        max-width: 100px;
                        width: 100px;
                        @include for-desktop-15 {
                            left: 111px;
                            min-width: 120px;
                            max-width: 120px;
                            width: 120px;
                        }
                    }
                    // &:nth-child(3) {
                    //     position: sticky !important;
                    //     z-index: 200;
                        
                    //     left: 201px;
                    //     min-width: 100px;
                    //     max-width: 100px;
                    //     width: 100px;
                    //     @include for-desktop-15 {
                    //         left: 231px;
                    //         min-width: 120px;
                    //         max-width: 120px;
                    //         width: 120px;
                    //     }
                    // }
                }
                // th {  
                //     &:nth-child(3) {
                //         z-index: 300;
                //     }
                // }

                .td_set_party_gstsie {
                    min-width: 135px;
                    max-width: 135px;
                    width: 135px;
                    @include for-desktop-15 {
                        min-width: 160px;
                        max-width: 160px;
                        width: 160px;
                    }
                }
                .td_set_selectie {
                    min-width: 150px;
                    max-width: 150px;
                    width: 150px;
                    @include for-desktop-15 {
                        min-width: 200px;
                        max-width: 200px;
                        width: 200px;
                    }
                }

                .td_set_gstsie {       
                    min-width: 80px;
                    max-width: 80px;
                    width: 80px;
                    @include for-desktop-15 {
                        min-width: 100px;
                        max-width: 100px;
                        width: 100px;
                    }
                }
                .td_set_amountie {       
                    min-width: 110px;
                    max-width: 110px;
                    width: 110px;
                    @include for-desktop-15 {
                        min-width: 130px;
                        max-width: 130px;
                        width: 130px;
                    }
                }

                // td {    
                //     &:first-child {
                //         min-width: 52px;
                //     }    
                //     &:nth-child(2) {
                //         left: 52px;   
                //         min-width: 140px;
                //         @include for-desktop-15 {
                //             min-width: 170px;
                //         }
                //     }
                // }

                .Select-Search {
                    border: 0px !important;
                }
            }

            &.snap-excel-table-moneylink{
                td, th {   
                    padding: 5px 0.3rem !important;
                    vertical-align: middle !important;

                    &.p-0 {
                        padding: 1px !important;
                    }

                    &:first-child {
                        left: 1px !important;
                        min-width: 150px;
                        max-width: 150px;
                        width: 150px;
                        @include for-desktop-15 {
                            min-width: 110px;
                            max-width: 110px;
                            width: 110px;
                        }
                    }    
                    &:nth-child(2) {
                        left: 151px;
                        min-width: 150px;
                        max-width: 150px;
                        width: 150px;
                        @include for-desktop-15 {
                            left: 161px;
                            min-width: 120px;
                            max-width: 120px;
                            width: 120px;
                        }
                    }
                    &:nth-child(3) {
                        // position: sticky !important;
                        // z-index: 200;
                        // left: 301px;
                        min-width: 150px;
                        max-width: 150px;
                        width: 150px;
                        @include for-desktop-15 {
                            // left: 231px;
                            min-width: 120px;
                            max-width: 120px;
                            width: 120px;
                        }
                    }
                    
                }

                // th {  
                //  &:nth-child(3) {
                //  z-index: 300;
                //  }}

                .td_set_selectie {
                    min-width: 150px;
                    max-width: 150px;
                    width: 150px;
                    @include for-desktop-15 {
                        min-width: 200px;
                        max-width: 200px;
                        width: 200px;
                    }
                }
                .td_set_amountie {       
                    min-width: 110px;
                    max-width: 110px;
                    width: 110px;
                    @include for-desktop-15 {
                        min-width: 130px;
                        max-width: 130px;
                        width: 130px;
                    }
                }
                .Select-Search {
                    border: 0px !important;
                }
            }


            .ie_def_td th {
                color: var(--gray-text) !important;
                background-color: var(--white) !important;
            }
        }
    }  
        
    .ie_upload_file {
        @extend .d-flex, .align-items-center;

        input[type="file"] {
            display: none;
        }

        // .custom-file-upload {
        //     border: 1px solid #ccc;
        //     display: inline-block;
        //     padding: 6px 12px;
        //     cursor: pointer;
        // }
    }
    
    .rbw-icon-btn {
        .green-icon {
            font-weight: 600 !important;
        }

        &:hover .green-icon::before {
            color: var(--white) !important;
        }
    }

    .ie_error_dot {
        position: absolute;
        top: 0px;
        right: 0px;

        &.ie_red_dot {
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-right: 10px solid var(--redc);
            border-bottom: 10px solid transparent;
        }

        &.ie_yellow_dot {
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-right: 10px solid var(--yellow);
            border-bottom: 10px solid transparent;
        }

        &.ie_green_dot {
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-right: 10px solid var(--greenc);
            border-bottom: 10px solid transparent;
        }
    }

    .ie_cr_dr {
        @extend .s-sbold;
        position: absolute;
        top: 0px;
        left: 5px;
        // font-weight: 600;
        font-size: 10px;
        color: var(--grayc-light);
        // letter-spacing: 1px;
        // border-radius: 100px;

        &.ie_cr_text {
            color: var(--dark-greenc);
        }

        &.ie_dr_text {
            color: var(--redc)
        }
    }

    .ie_status_msg {
        @extend .size-xvi, .d-block, .py-1, .px-2, .gray-text; 
        width: 105px;
        @include for-desktop-15 {
            width: 125px;
        }
        // font-size: 0.9rem;
        // @include for-desktop-15 {
        //     font-size: 0.8rem;
        // }
    }

    .ie_ProgressBar {
        @extend .mt-1, .py-1, .px-2;
        width: 100px;
    }

    .undu-icon {
        transform: rotateY(180deg);
    }


    .ie_icon_up_btn {
        @extend .d-flex, .align-items-center, .justify-content-center, .text-center, .px-1;

        width: 90px;
        // gap: 3px;
        @include for-desktop-15 {
            width: 100px;
            gap: 5px;
        }

        .ie_icon_btn {
            @extend .size-xx, .p-2;
            cursor: pointer;
            width: 35px;
        }

        // .ie_update_btn {
        //     @extend .size-xvii, .s-sbold;
        //     color: var(--white);
        //     background: var(--greenc);
        //     padding: 0.1rem 0.8rem;
        //     border-radius: 50px;
        //     border-color: var(--greenc);

        //     &:hover {
        //         color: var(--white);
        //         border-color: var(--greenc);
        //         background: var(--greenc);
        //     }
        // }
    }

    // -----
    // .sup-cust-check .styled-checkbox::before {   
    //     border: 1px solid var(--form-border);
    // }
    .star-td {
        @extend .size-xviii;
        margin-top: 10px;
    }

    .sup-cust-check {
        @extend .justify-content-center;
        margin-top: 7px;
    }

    .ie_status {
        // @extend .px-1, .pt-2, .text-center;
        @extend .text-center;
        min-width: 75px;
        // margin-top: 7px;
    }

    .ie_add_new {
        @extend .s-sbold, .green-text, .white-bg;
    }

    .ie_form {
        // @extend .p-1;
        padding: 5px !important;
        // border: 0px !important;

        border: 1px solid transparent !important;
        // border: 1px solid var(--form-border) !important;
        // border-bottom: 0px !important;
        // border-right: 0px !important;
        height: 32px;
        width: 140px;
        @include for-desktop-15 {
            width: 170px;
        
            height: 37px !important;
            // height: 38px;
        }
        color: var(--gray-text) !important;
        -webkit-text-fill-color: var(--gray-text) !important;
    }

    select.ie_form {
        @extend .p-0;
        background: var(--white);
    }

    .add-new-selectie {
        @extend .d-flex, .align-items-center;
        gap: 2px;

        .ie-add-icon {
            @extend .size-xxii, .cpointer, .p-1;
        }

        .ie_form {
            width: 110px;
            @include for-desktop-15 {
                width: 138px;
            }
        }
    }


    // Multi Select 
    .ie_form_multi {
        // @extend .p-1;
        // border: 1px solid var(--form-border) !important;
        width: 230px;

        .css-1jarjlr-control, .css-1f7ppyq-control {
            box-shadow: none !important;
            border: 0px !important;
            border-radius: 0px !important;

            min-height: 30px !important;

            @include for-desktop-15 {
                min-height: 37px !important;
            }
        }

        .css-g1d714-ValueContainer {
            padding: 2px 3px;
        }

        .css-1okebmr-indicatorSeparator {
            @extend .d-none;
        }

        .css-tlfecz-indicatorContainer {
            padding: 0 !important;
            color: var(--bluec) !important;
            margin-top: 4px;
        }

        .css-1gtu0rj-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            padding: 0px !important;
        }
        .css-tj5bde-Svg {
            color: var(--gray-text);
        }
    }

    .css-1nu9k0w-control,
    .css-1nu9k0w-control:focus,
    .css-1nu9k0w-control:hover {
        border: 1px solid var(--greenc) !important;
        border-radius: 0px !important;
        outline: unset !important;
        box-shadow: none !important;

        min-height: 30px !important;

        @include for-desktop-15 {
            min-height: 37px !important;
        }
    }

    .ie_form,
    .ie_form:focus,
    .css-1jarjlr-control:focus,
    .styled-checkbox:focus-visible {
        border: 1px solid var(--white) !important;
    }
    .ie_form:focus,
    .css-1jarjlr-control:focus,
    .styled-checkbox:focus-visible {
        border: 1px solid var(--greenc) !important;
        border-radius: 0px !important;
        outline: unset !important;
    }

    // .ie_form_multi * {
    //     @extend .border-0;
    //     // @extend .m-0;
    //     @extend .p-0;
    //     min-height: auto !important;
    //     height: auto !important;
    // }

    // End Multi Select ----

    // .ie_select_box {
    //     width: 200px;
    // }

    // td:nth-last-child(-n + 3) .ie_form {
    //     border-right: 1px solid var(--form-border) !important;
    // }    

    // .ie_error_dot {
    //     @extend .text-right;
    //     position: absolute;
    //     bottom: 0px;
    //     right: 1px;
    //     // line-height: 1;
    //     font-size: 0.65rem;

    //     @include for-desktop-15 {
    //         font-size: 0.9rem;
    //     }
    // }

    // .ie_error {
    //     @extend .d-block, .red-text, .text-right;
    //     position: absolute;
    //     bottom: 0;
    //     right: 2px;
    //     line-height: 1;
    //     font-size: 0.7rem;

    //     @include for-desktop-15 {
    //         font-size: 0.8rem;
    //     }
    // }

    // .ie_warning_error {
    //     @extend .d-block, .yellow-text, .text-right;
    //     position: absolute;
    //     bottom: 0;
    //     right: 2px;
    //     line-height: 1;
    //     font-size: 0.7rem;

    //     @include for-desktop-15 {
    //         font-size: 0.8rem;
    //     }
    // }


    .ie_date {
        @extend .text-center;
        min-width: 130px;
        max-width: 130px;
        width: 130px;
        @include for-desktop-15 {
            min-width: 150px;
            max-width: 150px;
            width: 150px;
        }
    }
    .ie_amount {
        @extend .text-right;
        // width: 135px !important;
        min-width: 120px;
        max-width: 120px;
        width: 120px;
        @include for-desktop-15 {
            min-width: 140px;
            max-width: 140px;
            width: 140px;
        }
    }
    .ie_pgst_pan_reg {
        min-width: 140px;
        max-width: 140px;
        width: 140px;
        @include for-desktop-15 {
            min-width: 160px;
            max-width: 160px;
            width: 160px;
        }
    }
    .ie_csi_gst {
        @extend .text-right;
        // width: 100px !important;
        // @include for-desktop-15 {
        //     width: 125px;
        // }
        min-width: 100px;
        max-width: 100px;
        width: 100px;
        // @include for-desktop-15 {
        //     min-width: 120px;
        //     max-width: 120px;
        //     width: 120px;
        // }
    }
    .ie_tds {
        @extend .text-right;
        width: 70px !important;
        // @include for-desktop-15 {
        //     width: 70px;
        // }
    }
    .ie_action {
        @extend .text-center;
    }

    .ie_form, .ie_desabled {
        // @extend .mb-3;
        // &::placeholder {
        //     @extend .size-xviii;
        //     @extend .s-reg;
        //     color: var(--placeholderc);
        // }
    
        background-color: transparent;
    
        &:focus {
            box-shadow: none !important;
        }
    
        &:disabled,
        &[readonly] {
            color: var(--bluec) !important;
            cursor: not-allowed !important;
            background-color: var(--disable-excel) !important;
            opacity: 1;
        }
    }
    
    .ie_tr_disabled, .ie_td_disabled, .ie_tr_disabled .ie_form {
        // opacity: 0.7;
        // background-color: #efefef !important;
        // background-color: #ebebeb !important;
        background-color: var(--disable-excel) !important;
        cursor: not-allowed !important;
    }

    .ietextarea {        
        min-height: 32px;
        overflow: auto !important;
        @include for-desktop-15 {
            min-height: 34px;
        }
    }

}


.ie_arrows {
    // @extend .d-flex, .align-items-center, .justify-content-end, .gap-10;
    @extend .d-flex, .align-items-center, .gap-10;

    .ie_arrows_icon {
        @extend .d-flex, .align-items-center, .justify-content-center, .cpointer;
        font-size: 9px;
        background: var(--gray-bg);
        width: 33px;
        height: 33px;
        border-radius: 100%;
    }

    .ie_right,
    .ie_left {
        rotate: 270deg;
    }
}

// Loader
.ie_loader_overlay {
    background: var(--black);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 10px;
}

.ie_loader_img {
    position: absolute;
    top: 40%;
    left: 45%;
    color: var(--white) !important;
    width: 150px;
    z-index: 1000;
}

// End Loader