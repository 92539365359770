.focus-none {
    &:focus-visible {
        outline: unset !important;
        // .btn {
        //     border-color: var(--btn-focus) !important;
        // }
    }
    
}

.btn {
    &:focus {
        // background-color: var(--greenc) !important;
        border-color: var(--btn-focus) !important;
        box-shadow: unset !important;
    }

    &:active {
        // background-color: var(--greenc) !important;
        border-color: var(--greenc) !important;
    }
}

.h-green-btn {
    font-size: 1.05rem;
    font-family: s-sbold !important;
    color: var(--white) !important;
    background-color: var(--greenc) !important;
    border-color: var(--greenc) !important;
    border-radius: 25px;
    padding: 0.25rem 1.25rem;

    &:hover {
        color: var(--white) !important;
        background-color: var(--greenc) !important;
        border-color: var(--greenc) !important;
    }

    &:focus {
        color: var(--white) !important;
        background-color: var(--greenc) !important;
        border-color: var(--btn-focus) !important;
        box-shadow: unset !important;
    }

    &:active {
        color: var(--white) !important;
        background-color: var(--greenc) !important;
        border-color: var(--greenc) !important;
    }
}

button {
    &:focus {
        box-shadow: unset !important;
    }
}


// Reset or Apply Button 

// bottom_save_btn

Button[disabled] {
    // border-color: var(--greenc) !important;
    // background: var(--greenc) !important;
    opacity: 0.9;
}

// .reset-apply-btn {
.reset_apply_btn_new {
    // @extend .mt-1, .d-flex, .justify-content-end;
    @extend .d-flex, .justify-content-end;
    // margin-top: 0.25rem !important;
    // margin-top: 0.25rem;
    gap: 15px;
}

// .center-apply-btn {
.center_apply_btn_new {
    @extend .mt-1, .d-flex, .justify-content-center;
    gap: 15px;
}

// .right-side-btn {
.right_side_btn_new {
    @extend .mt-1, .d-flex, .justify-content-between;
    gap: 15px;
}

// .reset-apply {
.reset_apply_new {
    // @extend .s-sbold, .size-xviii, .pl-4, .pr-4;
    @extend .s-sbold, .size-xviii;
    line-height: 1.2;

    padding-left: 1rem;
    padding-right: 1rem;

    @include for-tablet-big {
        padding-left: 1.1rem;
        padding-right: 1.1rem;
    }

    // height: calc(1.5em + 0.75rem + 4px);
    @include for-desktop-big {
        // height: calc(1.5em + 0.75rem + 4px);
    }

    border: 2px solid;
    border-radius: 50px;
    border-color: var(--greenc);

    // color: var(--white) !important;
    // background-color: var(--greenc);
}

.account_btn {
    @extend .s-reg, .size-xviii, .d-flex, .align-items-center, .p-0;

    border: 1px solid;
    border-color: var(--greenc);
    color: var(--bluec);

    &:hover,
    &:focus {
        color: var(--bluec) !important;
        background: var(--white) !important;
    }

    .account_btn_icon {
        @extend .size-xxiii, .d-inline-block;
        background: var(--greenc) !important;
        width: 42px;
        padding-right: 3px;
        // padding: 0.25rem 0;
    }

    .account_btn_text {
        @extend .d-inline-block;
        padding: 0.2rem 0.75rem;
    }

    // line-height: 1.2;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // @include for-tablet-big {
    //     padding-left: 1.3rem;
    //     padding-right: 1.3rem;
    // } 
}

.btn-success.disabled,
.btn-success:disabled {
    color: var(--white) !important;
    background-color: var(--greenc);
    border-color: var(--greenc);
}
.gray_btn_new:disabled {
    color: var(--grayc-dark) !important;
}

// .reset-btn {
.reset_btn_new {
    @extend .reset_apply_new;
    color: var(--greenc);

    // border-color: var(--greenc) !important;
    // background: var(--greenc) !important;
    &:focus {
        color: var(--greenc) !important;
        background: var(--white) !important;
    }

    &:hover {
        color: var(--white) !important;
        border-color: var(--greenc) !important;
        background: var(--greenc) !important;
    }
}

// .apply-btn, .btn.disabled {
.apply_btn_new,
.btn.disabled {
    @extend .reset_apply_new;
    color: var(--white);
    background: var(--greenc);
    border-color: var(--greenc) !important;
    background: var(--greenc) !important;

    &:focus {
        border-color: var(--btn-focus) !important;
        background: var(--greenc) !important;
    }

    &:hover {
        border-color: var(--greenc) !important;
        background: var(--greenc) !important;
    }
}

.gray_btn_new {
    @extend .reset_apply_new;
    color: var(--grayc-dark) !important;
    background: var(--gray-btn);
    border-color: var(--gray-btn) !important;
    background: var(--gray-btn) !important;

    &:focus {
        color: var(--grayc-dark);
        border-color: var(--btn-focus) !important;
        background: var(--gray-btn) !important;
    }

    &:hover {
        color: var(--grayc-dark);
        border-color: var(--gray-btn) !important;
        background: var(--gray-btn) !important;
    }
}

.btn-icons {
    @extend .size-xvi, .mr-1;
}

.btn.disabled,
.btn:disabled,
.disable-any {
    opacity: .6;
    cursor: not-allowed !important;
}

.btn-small {
    // @extend .s-reg;
    padding: 0.2rem 0.7rem !important;

    span {
        font-size: 0.9rem !important;
    }
}

.toogle-btn-ra {
    background-color: var(--grayc);
    border-color: var(--grayc);
    border-radius: 25px;
    .btn-primary {
        @extend .size-xviii, .s-sbold;
        color: var(--bluec);
        background-color: var(--grayc);
        border-color: var(--grayc);
        border-radius: 25px !important;
        margin: 0 1px;

        padding-left: 1rem;
        padding-right: 1rem;
        @include for-tablet-big {
            padding-left: 1.3rem;
            padding-right: 1.3rem;
        }

        &:focus,
        &.focus {
            box-shadow: unset !important;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &.dropdown-toggle,
        &.active {
            background-color: var(--greenc) !important;
            border-color: var(--greenc) !important;
            // border-radius: 25px !important;
        }
    }
}

.toogle-btn-ra-sm {
    background-color: var(--grayc);
    border-color: var(--grayc);
    border-radius: 25px;
    .btn-primary {
        @extend .size-xvi, .s-sbold;
        color: var(--bluec);
        background-color: var(--grayc);
        border-color: var(--grayc);
        border-radius: 25px !important;
        margin: 0 1px;

        padding: 0.20em 0.9rem;

        // padding-left: 1rem;
        // padding-right: 1rem;

        // @include for-tablet-big {
        //     padding-left: 1.3rem;
        //     padding-right: 1.3rem;
        // }

        &:focus,
        &.focus {
            box-shadow: unset !important;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &.dropdown-toggle,
        &.active {
            background-color: var(--greenc) !important;
            border-color: var(--greenc) !important;
            // border-radius: 25px !important;
        }
    }
}

// End Reset or Apply Button 



// Active Inactive Button
.activein_btn_group {
    border-radius: 25px !important;
    background-color: var(--grayc);
    gap: 2px;

    .success:hover {
        color: var(--white) !important;
    }

    .btn.activein_btn_new {
        // border: 0px solid !important;
        border-radius: 25px !important;
        border-color: var(--greenc) !important;

        &:hover,
        &:focus {
            border-color: var(--greenc) !important;
        }
    }

    .btn.deactivein_btn_new {
        color: var(--bluec) !important;
        background-color: var(--grayc) !important;
        border-color: var(--grayc) !important;

        &:hover,
        &:focus {
            border-color: var(--grayc) !important;
        }
    }
}

// End Active Inactive Button



.add-cat-btn {
    @extend .size-xvii, .s-sbold, .green-text, .border-0, .p-0, .bg-transparent;
    text-decoration: underline;

    &:focus {
        outline: 0 auto -webkit-focus-ring-color !important;
        // outline: 0 dotted!important;
        // -webkit-text-decoration-color: #000;
        // text-decoration-color: #000;
    }

    // font-size: 17px;
    // color: #77d7b1;
    // font-family: s-sbold!important;
    // border: 0!important;

}

.view-ref-btn, .btn_sm {
    @extend .size-xvii, .s-reg;
    color: var(--white);
    background: var(--greenc);
    padding: 0.1rem 0.8rem;
    border-radius: 50px;
    border-color: var(--greenc);

    &:hover {
        color: var(--white);
        border-color: var(--greenc);
        background: var(--greenc);
    }
}

.btn-flex,
.page-button-bar {
    @extend .d-flex, .flex-wrap, .align-items-center, .justify-content-end, .gap-15;

    .btn-flex-left {
        @extend .d-flex, .align-items-center, .mr-auto, .gap-15;
    }

    .btn-flex-right {
        @extend .d-flex, .align-items-center, .gap-15;
    }


    .btn-bar-delete {
        @extend .size-xxiii, .mr-2, .cpointer;
    }

    // .btn-bar-save-text {
    //     @extend .size-xviii, .blue-text;

    //     .styled-checkbox+label:before {
    //         width: 1.3rem !important;
    //         height: 1.3rem !important;
    //         margin-right: 5px !important;
    //         margin-top: 0px !important;
    //     }

    .styled-checkbox+label:before {
        width: 1.3rem !important;
        height: 1.3rem !important;
        margin-right: 5px !important;
        margin-top: 1px !important;
    }

    //     .styled-checkbox:checked+label:after {
    //         left: 0.27rem !important;
    //         top: 0.8rem !important;
    //     }

    //     @include for-desktop-big {
    //         .styled-checkbox+label:before {
    //             width: 1.35rem !important;
    //             height: 1.35rem !important;
    //             margin-right: 5px !important;
    //             margin-top: 0px !important;
    //         }

    //         .styled-checkbox:checked+label:after {
    //             left: 0.35rem !important;
    //             top: 0.95rem !important;
    //         }
    //     }

    //     .label-text {
    //         @extend .d-flex, .align-items-center, .gap-5;

    //         .smtext {
    //             margin-top: 2px;
    //         }
    //     }
    // }

}



// Switch button 
.toggle-switch-center {
    @extend .d-flex, .align-items-center;
}

.toggle-btn-bordered {
    // @extend .d-flex, .align-items-center;
    display: inline-flex;
    @extend .align-items-center;
    border: 1px solid var(--switch-gray) !important;
    padding: 0.27rem 0.75rem;
    // height: calc(1.5em + 0.75rem + 4px);
    border-radius: 25px;
    // @include for-desktop-big {
    //     height: calc(1.5em + 0.75rem + 4px);
    // } 
}

.toggle-switch-btn {
    // display: table;
    // margin: 0;
    // border: none;
    // padding: 6px 13px;
    // border-radius: 25px;
    // mb-0 border-0
    // border: 1px solid #d9d9d9!important; 
    
    // display: inline-flex ;

    .toggle-btn-sec-text {
        @extend .size-xviii, .s-sbold, .blue-text;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 24px;
        margin: 0 6px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked+.slider {
        background-color: var(--greenc);
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 2px;
        background-color: var(--white);
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 2px;
        background-color: var(--white);
        transition: .4s;
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--switch-track);
        transition: .4s;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

// End Switch button 


// Blink button
@keyframes blink {
    0% {
        background-color: var(--green-blink1);
    }

    50% {
        background-color: var(--green-blink2);
    }

    100% {
        background-color: var(--green-blink1);
    }
}

@-webkit-keyframes blink {
    0% {
        background-color: var(--green-blink1);
    }

    50% {
        background-color: var(--green-blink2);
    }

    100% {
        background-color: var(--green-blink1);
    }
}

.btn-blink {
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation: blink normal 1.5s infinite ease-in-out;
    /* Opera */
    @extend .reset_apply_new, .border-0;
}

// End Blink button


.fix-button-bar-bottom {
    @extend .position-fixed, .d-flex, .align-items-center, .gap-20;
    bottom: 0;
    background: var(--grayc) !important;
    z-index: 100;

    width: 88.5%;
    padding: 0.4rem 1.5rem 0.4rem 0.7rem;

    @include for-desktop-min {
        width: 91.4%;
    }

    @include for-desktop-big {
        width: 92.6%;
    }

    @include for-desktop-big-x {
        width: 94%;
    }

    .fbb-p {
        @extend .mb-0, .ml-auto;
    }

    .transpnt-btn {
        @extend .bg-transparent, .p-0, .border-0, .green-text, .s-sbold, .size-xviii;
        // line-height: 1.2;
    }
}